

    
export type Gender = 'Male' | 'Female';

export const Gender = {
    Male: 'Male' as Gender,
    Female: 'Female' as Gender
};


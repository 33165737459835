

    
export type FormUsage = 'Intern' | 'ExternQr' | 'ExternEmail';

export const FormUsage = {
    Intern: 'Intern' as FormUsage,
    ExternQr: 'ExternQr' as FormUsage,
    ExternEmail: 'ExternEmail' as FormUsage
};




    
export type LabelType = 'Standard' | 'Classification' | 'Supplier' | 'User' | 'CustomerOrderState' | 'PurchaseOrderState' | 'DeliveryPlace' | 'ProductGroup' | 'InventoryState' | 'SalesOrderSpecialist' | 'SalesOrderStatus' | 'OrderBranch' | 'CustomerBranch' | 'SalesOrderType' | 'Customer' | 'PurchaseOrderType' | 'PurchaseOrderDeliveryState' | 'Form' | 'KredDocumentState' | 'WunderClassification' | 'SalesOrderPool' | 'BinLocation' | 'DynamicCustomerLabel' | 'Employee';

export const LabelType = {
    Standard: 'Standard' as LabelType,
    Classification: 'Classification' as LabelType,
    Supplier: 'Supplier' as LabelType,
    User: 'User' as LabelType,
    CustomerOrderState: 'CustomerOrderState' as LabelType,
    PurchaseOrderState: 'PurchaseOrderState' as LabelType,
    DeliveryPlace: 'DeliveryPlace' as LabelType,
    ProductGroup: 'ProductGroup' as LabelType,
    InventoryState: 'InventoryState' as LabelType,
    SalesOrderSpecialist: 'SalesOrderSpecialist' as LabelType,
    SalesOrderStatus: 'SalesOrderStatus' as LabelType,
    OrderBranch: 'OrderBranch' as LabelType,
    CustomerBranch: 'CustomerBranch' as LabelType,
    SalesOrderType: 'SalesOrderType' as LabelType,
    Customer: 'Customer' as LabelType,
    PurchaseOrderType: 'PurchaseOrderType' as LabelType,
    PurchaseOrderDeliveryState: 'PurchaseOrderDeliveryState' as LabelType,
    Form: 'Form' as LabelType,
    KredDocumentState: 'KredDocumentState' as LabelType,
    WunderClassification: 'WunderClassification' as LabelType,
    SalesOrderPool: 'SalesOrderPool' as LabelType,
    BinLocation: 'BinLocation' as LabelType,
    DynamicCustomerLabel: 'DynamicCustomerLabel' as LabelType,
    Employee: 'Employee' as LabelType
};


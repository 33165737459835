import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import { environment } from './environments/environment';
import { extendConfiguration } from './config';

import { init } from '@sentry/browser';
import 'hammerjs';

extendConfiguration().then(async () => {
  if (environment.production) {
    enableProdMode();
  }

  if (environment.sentry) {
    init({
      dsn: environment.sentry,
      environment: environment.wunderApiUrl
        .split('.')
        .splice(2, 3)
        .join('.'),
      release: environment.version,
    });
  }

  const module = await import('./app/app.module');
  platformBrowserDynamic()
    .bootstrapModule(module.AppModule)
    .catch(err => console.error(err));
});



    
export type AddressSearchType = 'ExactAHV' | 'ExactFirstNameLastNameBirthday' | 'Similar';

export const AddressSearchType = {
    ExactAHV: 'ExactAHV' as AddressSearchType,
    ExactFirstNameLastNameBirthday: 'ExactFirstNameLastNameBirthday' as AddressSearchType,
    Similar: 'Similar' as AddressSearchType
};


import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


    import { AddressService } from './api/address.service';
    import { CustomerService } from './api/customer.service';
    import { DashboardService } from './api/dashboard.service';
    import { EmployeeService } from './api/employee.service';
    import { FormDefinitionService } from './api/formDefinition.service';
    import { FormInstanceService } from './api/formInstance.service';
    import { HistoryService } from './api/history.service';
    import { IntegrationTestService } from './api/integrationTest.service';
    import { KredDocumentService } from './api/kredDocument.service';
    import { LabelsService } from './api/labels.service';
    import { SalesOrderService } from './api/salesOrder.service';
    import { SalesOrderDocumentService } from './api/salesOrderDocument.service';
    import { SalesOrderPoolService } from './api/salesOrderPool.service';
    import { TrelloService } from './api/trello.service';
    import { WunderLabelService } from './api/wunderLabel.service';
    import { WunderMetadataService } from './api/wunderMetadata.service';

@NgModule({
imports:      [],
declarations: [],
exports:      [],
providers: [
AddressService,
CustomerService,
DashboardService,
EmployeeService,
FormDefinitionService,
FormInstanceService,
HistoryService,
IntegrationTestService,
KredDocumentService,
LabelsService,
SalesOrderService,
SalesOrderDocumentService,
SalesOrderPoolService,
TrelloService,
WunderLabelService,
WunderMetadataService ]
})
export class ApiModule {
public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
return {
ngModule: ApiModule,
providers: [ { provide: Configuration, useFactory: configurationFactory } ]
};
}

constructor( @Optional() @SkipSelf() parentModule: ApiModule,
@Optional() http: HttpClient) {
if (parentModule) {
throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
}
if (!http) {
throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
'See also https://github.com/angular/angular/issues/20575');
}
}
}



    
export type OrderStatus = 'NotSet' | 'Created' | 'Planning' | 'InProgress' | 'Ordered' | 'PreInProgress' | 'ProbationalReady' | 'ReadyForCheck' | 'InProduction' | 'WaitingFor' | 'ReadyForSubmission' | 'Delivered' | 'Rejected' | 'CalculationControl' | 'AvailableForFactura' | 'AdminPending' | 'Billed';

export const OrderStatus = {
    NotSet: 'NotSet' as OrderStatus,
    Created: 'Created' as OrderStatus,
    Planning: 'Planning' as OrderStatus,
    InProgress: 'InProgress' as OrderStatus,
    Ordered: 'Ordered' as OrderStatus,
    PreInProgress: 'PreInProgress' as OrderStatus,
    ProbationalReady: 'ProbationalReady' as OrderStatus,
    ReadyForCheck: 'ReadyForCheck' as OrderStatus,
    InProduction: 'InProduction' as OrderStatus,
    WaitingFor: 'WaitingFor' as OrderStatus,
    ReadyForSubmission: 'ReadyForSubmission' as OrderStatus,
    Delivered: 'Delivered' as OrderStatus,
    Rejected: 'Rejected' as OrderStatus,
    CalculationControl: 'CalculationControl' as OrderStatus,
    AvailableForFactura: 'AvailableForFactura' as OrderStatus,
    AdminPending: 'AdminPending' as OrderStatus,
    Billed: 'Billed' as OrderStatus
};


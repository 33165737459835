/* eslint-disable */
/**
* Ortho Team Api
* No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
*
* OpenAPI spec version: v1
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddSalesOrderFormResultDto } from '../model/addSalesOrderFormResultDto';
import { AddressLinkType } from '../model/addressLinkType';
import { AttachmentType } from '../model/attachmentType';
import { AttachmentUploadDto } from '../model/attachmentUploadDto';
import { CurrentEmployeeKvStatusCountDto } from '../model/currentEmployeeKvStatusCountDto';
import { CurrentEmployeePoolSubscriptionCountDto } from '../model/currentEmployeePoolSubscriptionCountDto';
import { EanDto } from '../model/eanDto';
import { InlineResponse200 } from '../model/inlineResponse200';
import { KvStatus } from '../model/kvStatus';
import { LabelDto } from '../model/labelDto';
import { OrderStatus } from '../model/orderStatus';
import { PreviewTypeDto } from '../model/previewTypeDto';
import { PrintOptions } from '../model/printOptions';
import { RuleViolation } from '../model/ruleViolation';
import { SalesOrderAddPositionDto } from '../model/salesOrderAddPositionDto';
import { SalesOrderAttachmentFileMetadataDto } from '../model/salesOrderAttachmentFileMetadataDto';
import { SalesOrderCloneDto } from '../model/salesOrderCloneDto';
import { SalesOrderCountDto } from '../model/salesOrderCountDto';
import { SalesOrderCreateDto } from '../model/salesOrderCreateDto';
import { SalesOrderDto } from '../model/salesOrderDto';
import { SalesOrderEditHeaderDto } from '../model/salesOrderEditHeaderDto';
import { SalesOrderEditPositionDto } from '../model/salesOrderEditPositionDto';
import { SalesOrderPositionReorderDto } from '../model/salesOrderPositionReorderDto';
import { SalesOrderSearchDto } from '../model/salesOrderSearchDto';
import { SalesOrderSearchDtoSearchResponse } from '../model/salesOrderSearchDtoSearchResponse';
import { SalesOrderSearchRequestDto } from '../model/salesOrderSearchRequestDto';
import { SalesOrderSearchResponse } from '../model/salesOrderSearchResponse';
import { UploadPolicy } from '../model/uploadPolicy';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SalesOrderService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addEan(body?: EanDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addEan(body?: EanDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addEan(body?: EanDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addEan(body?: EanDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/addEan`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param formId 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFormInstance(id: string, formId: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<AddSalesOrderFormResultDto>;
    public addFormInstance(id: string, formId: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AddSalesOrderFormResultDto>>;
    public addFormInstance(id: string, formId: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AddSalesOrderFormResultDto>>;
    public addFormInstance(id: string, formId: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling addFormInstance.');
        }
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling addFormInstance.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/form/${encodeURIComponent(String(formId))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPositions(id: string, body?: Array<SalesOrderAddPositionDto>, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public addPositions(id: string, body?: Array<SalesOrderAddPositionDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public addPositions(id: string, body?: Array<SalesOrderAddPositionDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public addPositions(id: string, body?: Array<SalesOrderAddPositionDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling addPositions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/positions`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param customerOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignCustomerOrder(id?: string, customerOrderId?: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public assignCustomerOrder(id?: string, customerOrderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public assignCustomerOrder(id?: string, customerOrderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public assignCustomerOrder(id?: string, customerOrderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (customerOrderId !== undefined && customerOrderId !== null) {
            queryParameters = queryParameters.set('customerOrderId', <any>customerOrderId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/assignCustomerOrder`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignMeAsSpecialist(id: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public assignMeAsSpecialist(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public assignMeAsSpecialist(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public assignMeAsSpecialist(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling assignMeAsSpecialist.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/assignMeAsSpecialist`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param newStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkedFunctionAndFitUpdateStatus(id: string, newStatus?: OrderStatus, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public checkedFunctionAndFitUpdateStatus(id: string, newStatus?: OrderStatus, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public checkedFunctionAndFitUpdateStatus(id: string, newStatus?: OrderStatus, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public checkedFunctionAndFitUpdateStatus(id: string, newStatus?: OrderStatus, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling checkedFunctionAndFitUpdateStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newStatus !== undefined && newStatus !== null) {
            queryParameters = queryParameters.set('newStatus', <any>newStatus);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/checked-function-and-fit-update-status`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clone(body?: SalesOrderCloneDto, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public clone(body?: SalesOrderCloneDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public clone(body?: SalesOrderCloneDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public clone(body?: SalesOrderCloneDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/clone`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public count(body?: SalesOrderSearchRequestDto, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public count(body?: SalesOrderSearchRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public count(body?: SalesOrderSearchRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public count(body?: SalesOrderSearchRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/count`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param externalId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countForKvStatus(externalId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CurrentEmployeeKvStatusCountDto>>;
    public countForKvStatus(externalId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CurrentEmployeeKvStatusCountDto>>>;
    public countForKvStatus(externalId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CurrentEmployeeKvStatusCountDto>>>;
    public countForKvStatus(externalId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (externalId !== undefined && externalId !== null) {
            queryParameters = queryParameters.set('externalId', <any>externalId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/SalesOrder/countKvStatus`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param externalId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countPools(externalId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CurrentEmployeePoolSubscriptionCountDto>>;
    public countPools(externalId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CurrentEmployeePoolSubscriptionCountDto>>>;
    public countPools(externalId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CurrentEmployeePoolSubscriptionCountDto>>>;
    public countPools(externalId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (externalId !== undefined && externalId !== null) {
            queryParameters = queryParameters.set('externalId', <any>externalId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/SalesOrder/countPoolSubscriptions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param externalId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countWithStatus(body?: SalesOrderSearchRequestDto, externalId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SalesOrderCountDto>>;
    public countWithStatus(body?: SalesOrderSearchRequestDto, externalId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SalesOrderCountDto>>>;
    public countWithStatus(body?: SalesOrderSearchRequestDto, externalId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SalesOrderCountDto>>>;
    public countWithStatus(body?: SalesOrderSearchRequestDto, externalId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (externalId !== undefined && externalId !== null) {
            queryParameters = queryParameters.set('externalId', <any>externalId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/countForSpecialist`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(body?: SalesOrderCreateDto, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public create(body?: SalesOrderCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public create(body?: SalesOrderCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public create(body?: SalesOrderCreateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.put(`${this.basePath}/api/SalesOrder`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteContact(id: string, type: AddressLinkType, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public deleteContact(id: string, type: AddressLinkType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public deleteContact(id: string, type: AddressLinkType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public deleteContact(id: string, type: AddressLinkType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteContact.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling deleteContact.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.delete(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/${encodeURIComponent(String(type))}/contacts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEan(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteEan(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteEan(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteEan(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteEan.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.delete(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/deleteEan`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFiles(id: string, name: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteFiles(id: string, name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteFiles(id: string, name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteFiles(id: string, name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteFiles.');
        }
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling deleteFiles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.delete(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/file/${encodeURIComponent(String(name))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSuccessfullyUploaded(id: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public documentSuccessfullyUploaded(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public documentSuccessfullyUploaded(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public documentSuccessfullyUploaded(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling documentSuccessfullyUploaded.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.put(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/documentUploadSuccess`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editHeader(id: string, body?: SalesOrderEditHeaderDto, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public editHeader(id: string, body?: SalesOrderEditHeaderDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public editHeader(id: string, body?: SalesOrderEditHeaderDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public editHeader(id: string, body?: SalesOrderEditHeaderDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling editHeader.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.put(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param itemNumber 
     * @param variantNumber 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editPosition(id: string, itemNumber: number, variantNumber: number, body?: SalesOrderEditPositionDto, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public editPosition(id: string, itemNumber: number, variantNumber: number, body?: SalesOrderEditPositionDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public editPosition(id: string, itemNumber: number, variantNumber: number, body?: SalesOrderEditPositionDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public editPosition(id: string, itemNumber: number, variantNumber: number, body?: SalesOrderEditPositionDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling editPosition.');
        }
        if (itemNumber === null || itemNumber === undefined) {
            throw new Error('Required parameter itemNumber was null or undefined when calling editPosition.');
        }
        if (variantNumber === null || variantNumber === undefined) {
            throw new Error('Required parameter variantNumber was null or undefined when calling editPosition.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.put(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/position/${encodeURIComponent(String(itemNumber))}/${encodeURIComponent(String(variantNumber))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param externalId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllEan(externalId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EanDto>>;
    public getAllEan(externalId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EanDto>>>;
    public getAllEan(externalId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EanDto>>>;
    public getAllEan(externalId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (externalId !== undefined && externalId !== null) {
            queryParameters = queryParameters.set('externalId', <any>externalId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/SalesOrder/getAllEan`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDefaultOrderBranch(observe?: 'body', reportProgress?: boolean): Observable<LabelDto>;
    public getDefaultOrderBranch(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LabelDto>>;
    public getDefaultOrderBranch(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LabelDto>>;
    public getDefaultOrderBranch(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/SalesOrder/defaultOrderBranch`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDetail(id: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public getDetail(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public getDetail(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public getDetail(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDownloadRequest(id: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getDownloadRequest(id: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getDownloadRequest(id: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getDownloadRequest(id: string, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDownloadRequest.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/download/request`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFiles(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SalesOrderAttachmentFileMetadataDto>>;
    public getFiles(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SalesOrderAttachmentFileMetadataDto>>>;
    public getFiles(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SalesOrderAttachmentFileMetadataDto>>>;
    public getFiles(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getFiles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/files`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrdersUsingEan(code: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getOrdersUsingEan(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getOrdersUsingEan(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getOrdersUsingEan(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling getOrdersUsingEan.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/SalesOrder/ordersUsingEan/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPreview(id: string, body?: PreviewTypeDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public getPreview(id: string, body?: PreviewTypeDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public getPreview(id: string, body?: PreviewTypeDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public getPreview(id: string, body?: PreviewTypeDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPreview.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/preview`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSearch(id: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderSearchDto>;
    public getSearch(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderSearchDto>>;
    public getSearch(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderSearchDto>>;
    public getSearch(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getSearch.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/search-dto`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadRequest(id: string, body?: AttachmentUploadDto, observe?: 'body', reportProgress?: boolean): Observable<UploadPolicy>;
    public getUploadRequest(id: string, body?: AttachmentUploadDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadPolicy>>;
    public getUploadRequest(id: string, body?: AttachmentUploadDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadPolicy>>;
    public getUploadRequest(id: string, body?: AttachmentUploadDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUploadRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/upload/request`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internNames(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public internNames(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public internNames(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public internNames(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/SalesOrder/internSpecialists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param orderStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isTransitionPossible(id: string, orderStatus: OrderStatus, observe?: 'body', reportProgress?: boolean): Observable<Array<RuleViolation>>;
    public isTransitionPossible(id: string, orderStatus: OrderStatus, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RuleViolation>>>;
    public isTransitionPossible(id: string, orderStatus: OrderStatus, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RuleViolation>>>;
    public isTransitionPossible(id: string, orderStatus: OrderStatus, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling isTransitionPossible.');
        }
        if (orderStatus === null || orderStatus === undefined) {
            throw new Error('Required parameter orderStatus was null or undefined when calling isTransitionPossible.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.put(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/status/${encodeURIComponent(String(orderStatus))}/check`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public print(body?: PrintOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public print(body?: PrintOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public print(body?: PrintOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public print(body?: PrintOptions, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/print`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param name 
     * @param s3Type 
     * @param attachmentType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public queueFileChange(id: string, name: string, s3Type: string, attachmentType?: AttachmentType, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public queueFileChange(id: string, name: string, s3Type: string, attachmentType?: AttachmentType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public queueFileChange(id: string, name: string, s3Type: string, attachmentType?: AttachmentType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public queueFileChange(id: string, name: string, s3Type: string, attachmentType?: AttachmentType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling queueFileChange.');
        }
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling queueFileChange.');
        }
        if (s3Type === null || s3Type === undefined) {
            throw new Error('Required parameter s3Type was null or undefined when calling queueFileChange.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (attachmentType !== undefined && attachmentType !== null) {
            queryParameters = queryParameters.set('attachmentType', <any>attachmentType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/file/${encodeURIComponent(String(name))}/queue-change/${encodeURIComponent(String(s3Type))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeAllocatedEmployee(id: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public removeAllocatedEmployee(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public removeAllocatedEmployee(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public removeAllocatedEmployee(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling removeAllocatedEmployee.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.delete(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/allocated-employee`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param itemNumber 
     * @param variantNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removePosition(id: string, itemNumber: number, variantNumber: number, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public removePosition(id: string, itemNumber: number, variantNumber: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public removePosition(id: string, itemNumber: number, variantNumber: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public removePosition(id: string, itemNumber: number, variantNumber: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling removePosition.');
        }
        if (itemNumber === null || itemNumber === undefined) {
            throw new Error('Required parameter itemNumber was null or undefined when calling removePosition.');
        }
        if (variantNumber === null || variantNumber === undefined) {
            throw new Error('Required parameter variantNumber was null or undefined when calling removePosition.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.delete(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/position/${encodeURIComponent(String(itemNumber))}/${encodeURIComponent(String(variantNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeReferencedSalesOrder(id: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public removeReferencedSalesOrder(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public removeReferencedSalesOrder(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public removeReferencedSalesOrder(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling removeReferencedSalesOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.delete(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/assignReference`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reorderPositions(id: string, body?: Array<SalesOrderPositionReorderDto>, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public reorderPositions(id: string, body?: Array<SalesOrderPositionReorderDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public reorderPositions(id: string, body?: Array<SalesOrderPositionReorderDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public reorderPositions(id: string, body?: Array<SalesOrderPositionReorderDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling reorderPositions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/reorder`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(body?: SalesOrderSearchRequestDto, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderSearchDtoSearchResponse>;
    public search(body?: SalesOrderSearchRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderSearchDtoSearchResponse>>;
    public search(body?: SalesOrderSearchRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderSearchDtoSearchResponse>>;
    public search(body?: SalesOrderSearchRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchArchived(body?: SalesOrderSearchRequestDto, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderSearchResponse>;
    public searchArchived(body?: SalesOrderSearchRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderSearchResponse>>;
    public searchArchived(body?: SalesOrderSearchRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderSearchResponse>>;
    public searchArchived(body?: SalesOrderSearchRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/searchArchived`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param externalId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setAllocatedEmployee(id: string, externalId: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public setAllocatedEmployee(id: string, externalId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public setAllocatedEmployee(id: string, externalId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public setAllocatedEmployee(id: string, externalId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setAllocatedEmployee.');
        }
        if (externalId === null || externalId === undefined) {
            throw new Error('Required parameter externalId was null or undefined when calling setAllocatedEmployee.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/allocated-employee/${encodeURIComponent(String(externalId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param addressId 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setContactAddress(id: string, addressId: number, type: AddressLinkType, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public setContactAddress(id: string, addressId: number, type: AddressLinkType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public setContactAddress(id: string, addressId: number, type: AddressLinkType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public setContactAddress(id: string, addressId: number, type: AddressLinkType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setContactAddress.');
        }
        if (addressId === null || addressId === undefined) {
            throw new Error('Required parameter addressId was null or undefined when calling setContactAddress.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling setContactAddress.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/contacts/${encodeURIComponent(String(type))}/${encodeURIComponent(String(addressId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param kvStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setKvStatus(id: string, kvStatus: KvStatus, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public setKvStatus(id: string, kvStatus: KvStatus, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public setKvStatus(id: string, kvStatus: KvStatus, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public setKvStatus(id: string, kvStatus: KvStatus, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setKvStatus.');
        }
        if (kvStatus === null || kvStatus === undefined) {
            throw new Error('Required parameter kvStatus was null or undefined when calling setKvStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.put(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/kv-status/${encodeURIComponent(String(kvStatus))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param referenceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setReferencedSalesOrder(id: string, referenceId: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public setReferencedSalesOrder(id: string, referenceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public setReferencedSalesOrder(id: string, referenceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public setReferencedSalesOrder(id: string, referenceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setReferencedSalesOrder.');
        }
        if (referenceId === null || referenceId === undefined) {
            throw new Error('Required parameter referenceId was null or undefined when calling setReferencedSalesOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.put(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/assignReference/${encodeURIComponent(String(referenceId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param itemNumber 
     * @param variantNumber 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setSerialNumbers(id: string, itemNumber: number, variantNumber: number, body?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public setSerialNumbers(id: string, itemNumber: number, variantNumber: number, body?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public setSerialNumbers(id: string, itemNumber: number, variantNumber: number, body?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public setSerialNumbers(id: string, itemNumber: number, variantNumber: number, body?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setSerialNumbers.');
        }
        if (itemNumber === null || itemNumber === undefined) {
            throw new Error('Required parameter itemNumber was null or undefined when calling setSerialNumbers.');
        }
        if (variantNumber === null || variantNumber === undefined) {
            throw new Error('Required parameter variantNumber was null or undefined when calling setSerialNumbers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.put(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/position/${encodeURIComponent(String(itemNumber))}/${encodeURIComponent(String(variantNumber))}/serialNumbers`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param orderStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setStatus(id: string, orderStatus: OrderStatus, observe?: 'body', reportProgress?: boolean): Observable<SalesOrderDto>;
    public setStatus(id: string, orderStatus: OrderStatus, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrderDto>>;
    public setStatus(id: string, orderStatus: OrderStatus, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrderDto>>;
    public setStatus(id: string, orderStatus: OrderStatus, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setStatus.');
        }
        if (orderStatus === null || orderStatus === undefined) {
            throw new Error('Required parameter orderStatus was null or undefined when calling setStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.put(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/status/${encodeURIComponent(String(orderStatus))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadToAbacus(id: string, name: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public uploadToAbacus(id: string, name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public uploadToAbacus(id: string, name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public uploadToAbacus(id: string, name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling uploadToAbacus.');
        }
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling uploadToAbacus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/${encodeURIComponent(String(id))}/file/${encodeURIComponent(String(name))}/upload-to-abacus`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateEan(body?: EanDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public validateEan(body?: EanDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public validateEan(body?: EanDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public validateEan(body?: EanDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/SalesOrder/validateEan`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

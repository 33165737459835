

    
export type AddressLinkType = 'LegalRepresentative' | 'ContactAddress' | 'CareHomeAddress' | 'BillingAddress' | 'DeliveryAddress' | 'DoctorAddress' | 'DoctorContactAddress' | 'ContactPersonAddress' | 'PaymentContactAddress' | 'AdditionalAddress' | 'PaymentAddress' | 'Unknown';

export const AddressLinkType = {
    LegalRepresentative: 'LegalRepresentative' as AddressLinkType,
    ContactAddress: 'ContactAddress' as AddressLinkType,
    CareHomeAddress: 'CareHomeAddress' as AddressLinkType,
    BillingAddress: 'BillingAddress' as AddressLinkType,
    DeliveryAddress: 'DeliveryAddress' as AddressLinkType,
    DoctorAddress: 'DoctorAddress' as AddressLinkType,
    DoctorContactAddress: 'DoctorContactAddress' as AddressLinkType,
    ContactPersonAddress: 'ContactPersonAddress' as AddressLinkType,
    PaymentContactAddress: 'PaymentContactAddress' as AddressLinkType,
    AdditionalAddress: 'AdditionalAddress' as AddressLinkType,
    PaymentAddress: 'PaymentAddress' as AddressLinkType,
    Unknown: 'Unknown' as AddressLinkType
};


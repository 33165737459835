

    
export type AddressType = 'General' | 'InternalDelivery' | 'Doctor';

export const AddressType = {
    General: 'General' as AddressType,
    InternalDelivery: 'InternalDelivery' as AddressType,
    Doctor: 'Doctor' as AddressType
};




    
export type AttachmentType = 'Default' | 'Image' | 'Prescription' | 'DimensionSheet' | 'AssignmentDocument' | 'OrderForm' | 'DimensionMinutes' | 'TreatmentMinutes' | 'DoctorReport' | 'Dunning' | 'Disposal' | 'DepositsAudit' | 'Correspondence' | 'TechnicalDocument' | 'OrderAcknowledgementOrDeliverySlip' | 'CustomerSheet' | 'DepositReceipt' | 'PdfOfForm' | 'OrderAcknowledgement' | 'DeliverySlip' | 'RentalAgreement' | 'Quote';

export const AttachmentType = {
    Default: 'Default' as AttachmentType,
    Image: 'Image' as AttachmentType,
    Prescription: 'Prescription' as AttachmentType,
    DimensionSheet: 'DimensionSheet' as AttachmentType,
    AssignmentDocument: 'AssignmentDocument' as AttachmentType,
    OrderForm: 'OrderForm' as AttachmentType,
    DimensionMinutes: 'DimensionMinutes' as AttachmentType,
    TreatmentMinutes: 'TreatmentMinutes' as AttachmentType,
    DoctorReport: 'DoctorReport' as AttachmentType,
    Dunning: 'Dunning' as AttachmentType,
    Disposal: 'Disposal' as AttachmentType,
    DepositsAudit: 'DepositsAudit' as AttachmentType,
    Correspondence: 'Correspondence' as AttachmentType,
    TechnicalDocument: 'TechnicalDocument' as AttachmentType,
    OrderAcknowledgementOrDeliverySlip: 'OrderAcknowledgementOrDeliverySlip' as AttachmentType,
    CustomerSheet: 'CustomerSheet' as AttachmentType,
    DepositReceipt: 'DepositReceipt' as AttachmentType,
    PdfOfForm: 'PdfOfForm' as AttachmentType,
    OrderAcknowledgement: 'OrderAcknowledgement' as AttachmentType,
    DeliverySlip: 'DeliverySlip' as AttachmentType,
    RentalAgreement: 'RentalAgreement' as AttachmentType,
    Quote: 'Quote' as AttachmentType
};




    
export type PrintReportType = 'AddressLabel' | 'OrderLabel';

export const PrintReportType = {
    AddressLabel: 'AddressLabel' as PrintReportType,
    OrderLabel: 'OrderLabel' as PrintReportType
};


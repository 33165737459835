

    
export type OrderType = 'Order' | 'Bill' | 'Offer' | 'Prescription' | 'Accident' | 'Rental' | 'Unknown';

export const OrderType = {
    Order: 'Order' as OrderType,
    Bill: 'Bill' as OrderType,
    Offer: 'Offer' as OrderType,
    Prescription: 'Prescription' as OrderType,
    Accident: 'Accident' as OrderType,
    Rental: 'Rental' as OrderType,
    Unknown: 'Unknown' as OrderType
};


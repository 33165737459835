

    
export type TechnicalUserType = 'Abacus' | 'System';

export const TechnicalUserType = {
    Abacus: 'Abacus' as TechnicalUserType,
    System: 'System' as TechnicalUserType
};




    
export type FormFieldDataType = 'Text' | 'Numeric' | 'Enum' | 'Info' | 'Checkbox' | 'Date' | 'Canvas' | 'FreeCheckbox' | 'Spacer' | 'File';

export const FormFieldDataType = {
    Text: 'Text' as FormFieldDataType,
    Numeric: 'Numeric' as FormFieldDataType,
    Enum: 'Enum' as FormFieldDataType,
    Info: 'Info' as FormFieldDataType,
    Checkbox: 'Checkbox' as FormFieldDataType,
    Date: 'Date' as FormFieldDataType,
    Canvas: 'Canvas' as FormFieldDataType,
    FreeCheckbox: 'FreeCheckbox' as FormFieldDataType,
    Spacer: 'Spacer' as FormFieldDataType,
    File: 'File' as FormFieldDataType
};


      export * from './address.service';
      import { AddressService } from './address.service';
      export * from './customer.service';
      import { CustomerService } from './customer.service';
      export * from './dashboard.service';
      import { DashboardService } from './dashboard.service';
      export * from './employee.service';
      import { EmployeeService } from './employee.service';
      export * from './formDefinition.service';
      import { FormDefinitionService } from './formDefinition.service';
      export * from './formInstance.service';
      import { FormInstanceService } from './formInstance.service';
      export * from './history.service';
      import { HistoryService } from './history.service';
      export * from './integrationTest.service';
      import { IntegrationTestService } from './integrationTest.service';
      export * from './kredDocument.service';
      import { KredDocumentService } from './kredDocument.service';
      export * from './labels.service';
      import { LabelsService } from './labels.service';
      export * from './salesOrder.service';
      import { SalesOrderService } from './salesOrder.service';
      export * from './salesOrderDocument.service';
      import { SalesOrderDocumentService } from './salesOrderDocument.service';
      export * from './salesOrderPool.service';
      import { SalesOrderPoolService } from './salesOrderPool.service';
      export * from './trello.service';
      import { TrelloService } from './trello.service';
      export * from './wunderLabel.service';
      import { WunderLabelService } from './wunderLabel.service';
      export * from './wunderMetadata.service';
      import { WunderMetadataService } from './wunderMetadata.service';
  export const APIS = [AddressService, CustomerService, DashboardService, EmployeeService, FormDefinitionService, FormInstanceService, HistoryService, IntegrationTestService, KredDocumentService, LabelsService, SalesOrderService, SalesOrderDocumentService, SalesOrderPoolService, TrelloService, WunderLabelService, WunderMetadataService];

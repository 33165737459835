

    
export type HistoryEntryType = 'Change' | 'Comment' | 'Link' | 'EmailSent';

export const HistoryEntryType = {
    Change: 'Change' as HistoryEntryType,
    Comment: 'Comment' as HistoryEntryType,
    Link: 'Link' as HistoryEntryType,
    EmailSent: 'EmailSent' as HistoryEntryType
};




    
export type FormDefinitionStatus = 'Draft' | 'Active';

export const FormDefinitionStatus = {
    Draft: 'Draft' as FormDefinitionStatus,
    Active: 'Active' as FormDefinitionStatus
};




    
export type KvStatus = 'None' | 'Created' | 'Audited' | 'Released' | 'RequestSahb' | 'WaitingForPrescription' | 'WaitingForPrescriptionAdmin' | 'WaitingForSignature' | 'Open' | 'Submitted' | 'PreliminaryDecision' | 'Approved' | 'Rejected' | 'SubmissionBySahbDirect' | 'SubmissionBySahbOt';

export const KvStatus = {
    None: 'None' as KvStatus,
    Created: 'Created' as KvStatus,
    Audited: 'Audited' as KvStatus,
    Released: 'Released' as KvStatus,
    RequestSahb: 'RequestSahb' as KvStatus,
    WaitingForPrescription: 'WaitingForPrescription' as KvStatus,
    WaitingForPrescriptionAdmin: 'WaitingForPrescriptionAdmin' as KvStatus,
    WaitingForSignature: 'WaitingForSignature' as KvStatus,
    Open: 'Open' as KvStatus,
    Submitted: 'Submitted' as KvStatus,
    PreliminaryDecision: 'PreliminaryDecision' as KvStatus,
    Approved: 'Approved' as KvStatus,
    Rejected: 'Rejected' as KvStatus,
    SubmissionBySahbDirect: 'SubmissionBySahbDirect' as KvStatus,
    SubmissionBySahbOt: 'SubmissionBySahbOt' as KvStatus
};


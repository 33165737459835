

    
export type RuleViolation = 'FunctionAndFitNotChecked' | 'NoSubmissionDate' | 'NoPositions' | 'NoPrescription' | 'NoRentalAgreement' | 'NotAllRequiredDocumentsInAbacus' | 'ValueIsZero' | 'WrongKvStatus' | 'MissingSerialShoe' | 'NoAdminPendingPermission';

export const RuleViolation = {
    FunctionAndFitNotChecked: 'FunctionAndFitNotChecked' as RuleViolation,
    NoSubmissionDate: 'NoSubmissionDate' as RuleViolation,
    NoPositions: 'NoPositions' as RuleViolation,
    NoPrescription: 'NoPrescription' as RuleViolation,
    NoRentalAgreement: 'NoRentalAgreement' as RuleViolation,
    NotAllRequiredDocumentsInAbacus: 'NotAllRequiredDocumentsInAbacus' as RuleViolation,
    ValueIsZero: 'ValueIsZero' as RuleViolation,
    WrongKvStatus: 'WrongKvStatus' as RuleViolation,
    MissingSerialShoe: 'MissingSerialShoe' as RuleViolation,
    NoAdminPendingPermission: 'NoAdminPendingPermission' as RuleViolation
};

